.header {
  top: 0%;
  background-color: #45cc5a;
  height: 40px;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header__link {
  color: white;
}

.a_header {
  color: white;
}

.btn_login {
  position: absolute;
  background: white;
  border-radius: 30px;
  color: black;
  padding: 0.4% 1%;
  border: none;
  height: 30px;
  text-transform: uppercase;
  right: 12%;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:active,
  &:focus {
    outline: none;
  }
}

@media only screen and (max-width: 500px) {
  .btn_login {
    right: 35%;
  }
}

@media only screen and (min-width: 500px) and (max-width: 1080px) {
  .btn_login {
    right: 25%;
  }
}
